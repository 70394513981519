<template>
  <div id="cropper">
    <div id="header"
         style="width:100%;box-sizing: border-box;padding:10px;height:50px;background:#003333;position: fixed;top:0px;z-index:999;color:#fff;text-align:center">
      <div style="position:absolute;left:5px;top:13px;">
        <img src="../../public/static/img/uploadImg/stmc.png" style="width:25px;height:25px;margin-right:5px;"/>
        <span v-text="params.stmc" style="vertical-align:super"></span>
      </div>
      <div style="position:absolute;right:10px;top:13px;">
        <img src="../../public/static/img/uploadImg/xm.png" style="width:25px;height:25px;margin-right:5px;"/>
        <span v-text="params.xm" style="vertical-align:super"></span>
      </div>

    </div>
    <div id="footer"
         style="width:100%;box-sizing: border-box;padding:10px;height:60px;background:#33CC99;position: fixed;bottom:0px;z-index:999;text-align:center;color:#fff;font-size:23px;line-height:40px;"
         @click="upload">
      上传作答图像
    </div>

    <div style="padding:70px 10px;box-sizing: border-box">
      <div v-for="(item,index) in params.txqys" :key="index"
           style="margin-bottom:10px;padding-bottom:10px;text-align: center;border-bottom: 1px dashed #929799">
        <label>
          <div style="margin-bottom: 15px">
            <span style="font-size:18px;font-weight:bold">作答图像 {{ index + 1 }}</span>
          </div>
          <div v-show="changeOPtions('imgViewShow',index)">
            <img :src="changeOPtions('imgUrl',index) || initImgUrl" style="width: 100%;max-height:400px;"
                 :data-item="changeOPtions('myType',index)"/>
            <input name="files" type="file" accept="image/*" value="" capture="camera"
                   @change="uploadImg($event,index)" style="display: none" :ref="getStr('imginput',index)"/>
          </div>

          <div class="vue-cropper" v-if="!changeOPtions('imgViewShow',index)">
            <vue-cropper
                :ref="getStr('cropper',index)"
                :img="changeOPtions('imgUrl',index)"
                :outputSize=option.outputSize
                :outputType=option.outputType
                :canMove=option.canMove
                :original=option.original
                :infoTrue=option.infoTrue
                :centerBox=option.centerBox
                :canMoveBox=option.canMoveBox
                :canScale=option.canScale
                :fixed=option.fixed
                :fixedNumber=option.fixedNumber
                :full=option.full
                :mode=option.mode
                :viewMode=option.viewMode
                :info="option.info"
                :high="option.high"
                :enlarge="option.enlarge"
                :autoCrop="option.autoCrop"
                autoCropWidth="99999"
                autoCropHeight="99999">
            </vue-cropper>
          </div>
        </label>
        <div style="text-align: center;margin: 0 auto;padding-top:10px" v-show="changeOPtions('imgUrl',index)">
          <el-button-group>
            <el-button size="medium" type="primary" icon="el-icon-camera" @click="chooseImg(index)" class="btnUploadImg"
                       v-if="changeOPtions('imgViewShow',index)"
                       :style="{ padding: changeOPtions('btnPadding',index)}">重新拍照
            </el-button>
            <el-button size="medium" type="primary" icon="el-icon-check" @click="changeImgViewShow(index,false)"
                       class="btnUploadImg"
                       v-if="changeOPtions('imgViewShow',index)"
                       :style="{ padding: changeOPtions('btnPadding',index)}">裁剪图片
            </el-button>
            <!--<el-button size="medium" type="primary" icon="el-icon-crop" @click="crop(index)"  v-show="changeOPtions('btnCropShow',index)" v-bind:style="{ padding: changeOPtions('btnPadding',index)}">裁剪</el-button>-->

            <el-button size="medium" type="primary" icon="el-icon-refresh-left" @click="rotateLeft(index)"
                       v-if="!changeOPtions('imgViewShow',index)"
                       :style="{ padding: changeOPtions('btnPadding',index)}">向左转
            </el-button>
            <el-button size="medium" type="primary" icon="el-icon-refresh-right" @click="rotateRight(index)"
                       v-if="!changeOPtions('imgViewShow',index)"
                       :style="{ padding: changeOPtions('btnPadding',index)}">向右转
            </el-button>
            <el-button size="medium" type="primary" icon="el-icon-check" @click="saveCrop(index)"
                       v-if="!changeOPtions('imgViewShow',index)"
                       :style="{ padding: changeOPtions('btnPadding',index)}">裁剪
            </el-button>
            <el-button size="medium" type="primary" icon="el-icon-back" @click="changeImgViewShow(index,true)"
                       v-if="!changeOPtions('imgViewShow',index)"
                       :style="{ padding: changeOPtions('btnPadding',index)}">返回
            </el-button>
          </el-button-group>
        </div>
      </div>
      <div v-show="errImgViewShow">
        <img :src="initImgUrl" style="width: 100%;max-height:400px;"/>
      </div>

    </div>
    <!-- MQTT 组件 -->
    <!--        <Mqtt ref="mqtt"></Mqtt>-->
  </div>

</template>

<script>

import {handleAlert} from "@/utils/confirm";
import {Loading} from 'element-ui';
import {sendAjax} from '@/utils/ajaxUtil'
import ImageCompressor from 'image-compressor.js'

// MQTT 组件
// import Mqtt from '@/components/Mqttws';

export default {
  name: "uploadImage",
  components: {},
  created() {
    this.req = this.$route.query.req
    this.getXsjcxx()//获取学生基础信息
  },
  data() {
    return {
      req: "",
      quality: 0.3,
      maxSize: 1500,
      initImgUrl: require("../../public/static/img/uploadImg/djpz.png"),
      imgUrl0: "",
      imgUrl1: "",
      imgUrl2: "",
      imgUrl3: "",
      myType0: "1",
      myType1: "1",
      myType2: "1",
      myType3: "1",
      btnShow0: false,
      btnShow1: false,
      btnShow2: false,
      btnShow3: false,
      btnSaveCropShow0: false,
      btnSaveCropShow1: false,
      btnSaveCropShow2: false,
      btnSaveCropShow3: false,
      imgViewShow0: true,
      imgViewShow1: true,
      imgViewShow2: true,
      imgViewShow3: true,
      btnPadding0: '6px 6px',
      btnPadding1: '6px 6px',
      btnPadding2: '6px 6px',
      btnPadding3: '6px 6px',
      option: {
        outputSize: 1, // 裁剪生成图片的质量 0.1 - 1
        outputType: 'png', //	裁剪生成图片的格式 jpeg || png || webp
        canScale: false, // 图片是否允许滚轮缩放 默认true
        canMove: false, //上传图片是否可以移动 默认true
        // fixedBox: true, // 固定截图框大小 不允许改变	false	true | false
        fixed: false, //是否开启截图框宽高固定比例
        original: false, // 上传图片按照原始比例渲染	false	true | false
        infoTrue: true, // 为展示真实输出图片宽高 false 展示看到的截图框宽高	false	true | false
        centerBox: true, // 截图框是否被限制在图片里面	false	true | false
        canMoveBox: true, //截图框能否拖动	true	true | false
        fixedNumber: [1, 1], // 截图框的宽高比例 [宽度, 高度]
        full: true, //是否输出原图比例的截图 默认false
        mode: 'contain',
        viewMode: 0,
        enlarge: 1,
        info: true,
        high: true,
        autoCrop: true,
      },
      uploadFiles: [], //上传图像，经过压缩处理之后的文件
      params: {},
      errImgViewShow: false,
      cropThePictureLoading: null, //正在处理作答图片的loading
    }
  },
  methods: {
    getXsjcxx() {
      //初始化参数
      this.uploadFiles = []
      this.params = {
        slid: "",
        ksdm: "",
        stid: "",
        ksid: "",
        kmdm: "",
        stmc: "",
        xm: "",
        txqys: [],
        afterUploadFiles: [],
        txdz: "",
      }
      let bodyParams = {
        req: this.req,
        version: localStorage.getItem("SystemVersion"),
      }
      this.$ajax.post(localStorage.getItem('ApiUrl') + '/upload/getXsjcxx?t=' + Math.random() * 100000000000000000, bodyParams).then(async (response) => {
        let resp = response.data;
        if (resp.success) {
          let data = resp.content
          //初始化一些变量
          this.params.slid = data.slid;
          this.params.ksdm = data.ksdm;
          this.params.stid = data.stid;
          this.params.ksid = data.ksid;
          this.params.bmh = data.bmh;
          this.params.kmdm = data.kmdm;
          this.params.stmc = data.stmc;
          this.params.xm = data.xm;
          this.params.txqys = data.txqys;
          this.params.txdz = data.txdz;
          // let self = this;
          // const toBase64ResArr = await Promise.all(
          //     this.params.txqys.map(txqy => {
          //       return (async () => {
          //          return self.toBase64(this.params.txdz + this.params.slid + "/" + this.params.ksdm + "/" + this.params.kmdm + "/" + this.params.ksid + "/" + this.params.ksid + "_" + txqy + ".jpg");
          //       })();
          //     })
          // );
          // toBase64ResArr.forEach((temp, index) => {
          //   if (temp !== "") {
          //     let imgTemp = this.getStr('imgUrl', index);
          //     let tempMyType = this.getStr('myType', index);
          //     let tempBtnShow = this.getStr('btnShow', index);
          //     let tempbtnSaveCropShow = this.getStr('btnSaveCropShow', index);
          //     let tempImgViewShow = this.getStr('imgViewShow', index);
          //     if (self[tempMyType] == 1) {
          //       self[tempMyType] = 2;
          //       this[tempBtnShow] = true;
          //       self[tempbtnSaveCropShow] = true;
          //       self[tempImgViewShow] = true;
          //     }
          //     self[imgTemp] = temp;
          //     self.uploadFiles[index] = temp;
          //   }
          // })
        } else {
          handleAlert(resp.message, 'error');
          this.errImgViewShow = true;
        }
      })
    },
    getBase64(url, callback) {
      let img = new Image(),
          dataURL = '';
      img.src = url
      img.setAttribute("crossOrigin", 'Anonymous')
      img.onload = () => {
        let canvas = document.createElement("canvas"),
            width = img.width,
            height = img.height;
        canvas.width = width;
        canvas.height = height;
        canvas.getContext("2d").drawImage(img, 0, 0, width, height);
        dataURL = canvas.toDataURL('image/jpeg');
        callback ? callback(dataURL) : null;
      };
      img.onerror = () => {
        callback ? callback(dataURL) : null;
      }
    },
    toBase64(url) {
      return new Promise((resolve, reject) => {
        this.getBase64(url, (data) => {
          resolve(data)
        })
      })
    },
    changeOPtions(str, index) {
      let temp = str + index;
      let tempData = this[`${temp}`];
      return tempData;
    },
    getStr(str, index) {
      let temp = str + index;
      return temp;
    },
    uploadImg(e, index) {
      let self = this;
      let files = e.target.files || e.dataTransfer.files;
      //如果文件为空或者文件的类型不是图片，则返回false
      if (!files.length || !this.isImageType(files[0].name.substring(files[0].name.lastIndexOf('.') + 1))) {
        handleAlert("只能上传图片格式的文件,请检查后从新选择图片", 'error');
        return false;
      }
      if (files[0].size === 0) {
        handleAlert("解析到上传文件的大小为0，请更换图片，或者浏览器重试", 'error');
      }
      this.imgPreview(files[0], index);
      //是否显示裁剪框等
      let tempMyType = this.getStr('myType', index);
      let tempBtnShow = this.getStr('btnShow', index);
      //let tempBtnCropShow=this.getStr('btnCropShow',index);
      let tempbtnSaveCropShow = this.getStr('btnSaveCropShow', index);
      let tempImgViewShow = this.getStr('imgViewShow', index);
      if (self[tempMyType] == 1) {
        self[tempMyType] = 2;
        this[tempBtnShow] = true;
        //self[tempBtnCropShow]= true;
        self[tempbtnSaveCropShow] = true;
        self[tempImgViewShow] = true;
      }
    },
    isImageType(str) {
      // toLowerCase() 将字符串转换为小写，返回一个新的字符串
      return ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp', 'psd', 'svg', 'tiff'].indexOf(str.toLowerCase()) !== -1
    },
    // 上传图像
    imgPreview(file, index) {
      let self = this;
      let Orientation;
      //去获取拍照时的信息，解决拍出来的照片旋转问题
      self.Exif.getData(file, function () {
        Orientation = self.Exif.getTag(this, 'Orientation');
      });
      // 看支持不支持FileReader
      if (!file || !window.FileReader) return;
      if (/^image/.test(file.type)) {
        // 创建一个reader
        let reader = new FileReader();
        // 将图片2将转成 base64 格式
        reader.readAsDataURL(file);
        // 读取成功后的回调
        reader.onload = function () {
          let result = this.result;
          let img = new Image();
          img.src = result;
          img.setAttribute('crossOrigin', 'anonymous');
          img.style.display = "none";
          var colorSum = 0;
          img.onload = function () {
            let expectWidth = img.naturalWidth;
            let expectHeight = img.naturalHeight;
            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');
            canvas.width = expectWidth;
            canvas.height = expectHeight;
            // 铺底色
            ctx.fillStyle = '#fff';
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            //修复ios上传图片的时候 被旋转的问题
            if (Orientation != '' && Orientation != 1) {
              switch (Orientation) {
                case 6: //需要顺时针（向左）90度旋转
                  self.rotateImg(img, 'left', canvas);
                  break;
                case 8: //需要逆时针（向右）90度旋转
                  self.rotateImg(img, 'right', canvas);
                  break;
                case 3: //需要180度旋转
                  self.rotateImg(img, 'right', canvas); //转两次
                  self.rotateImg(img, 'right', canvas);
                  break;
              }
            }
            let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
            let data = imageData.data;
            let r, g, b, avg;

            for (let x = 0, len = data.length; x < len; x += 4) {
              r = data[x];
              g = data[x + 1];
              b = data[x + 2];

              avg = Math.floor((r + g + b) / 3);
              colorSum += avg;
            }

            let brightness = Math.floor(colorSum / (this.width * this.height));
            console.log("当前图片亮度" + brightness)
            if (brightness <= 20) {
              handleAlert("当前图片亮度过暗，可能会影响教师评阅，请更换图片后上传。", 'error');
              return false
            } else if (brightness >= 250) {
              handleAlert("当前图片亮度过亮，可能会影响教师评阅，请更换图片后上传。", 'error');
              return false
            }
            let temp = self.getStr('imgUrl', index);
            self[temp] = img.src;
            self.uploadFiles[index] = self[temp];
          };
        };
      }
    },
    // 图像压缩
    compress(file) {
      let _this = this
      const imageCompressor = new ImageCompressor();
      return new Promise((resolve, reject) => {
        imageCompressor.compress(file, {
          quality: _this.quality,
        }).then((result) => { // 压缩成功的回调 Handle the compressed image file.
          resolve(result)
        }).catch((err) => { // 压缩失败的回调 Handle the error
          handleAlert("图片压缩出现异常,请刷新页面后重新上传。", 'error');
        })
      })
    },
    rotateImg(img, direction, canvas) {
      //最小与最大旋转方向，图片旋转4次后回到原方向
      const min_step = 0;
      const max_step = 3;
      if (img == null) return;
      //img的高度和宽度不能在img元素隐藏后获取，否则会出错
      let height = img.height;
      let width = img.width;
      let step = 2;
      if (step == null) {
        step = min_step;
      }
      if (direction == 'right') {
        step++;
        //旋转到原位置，即超过最大值
        step > max_step && (step = min_step);
      } else {
        step--;
        step < min_step && (step = max_step);
      }
      //旋转角度以弧度值为参数
      let degree = (step * 90 * Math.PI) / 180;
      let ctx = canvas.getContext('2d');
      switch (step) {
        case 0:
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0);
          break;
        case 1:
          canvas.width = height;
          canvas.height = width;
          ctx.rotate(degree);
          ctx.drawImage(img, 0, -height);
          break;
        case 2:
          canvas.width = width;
          canvas.height = height;
          ctx.rotate(degree);
          ctx.drawImage(img, -width, -height);
          break;
        case 3:
          canvas.width = height;
          canvas.height = width;
          ctx.rotate(degree);
          ctx.drawImage(img, -width, 0);
          break;
      }
    },
    chooseImg(index) {
      let temp = this.getStr('imginput', index)
      this.$refs[temp][0].click()
    },
    changeImgViewShow(index, tOrF) {
      let temp = this.getStr('imgViewShow', index)
      this[temp] = tOrF;
    },
    //裁剪
    crop(index) {
      let tempBtnSaveCropShow = this.getStr('btnSaveCropShow', index);
      //let tempBtnCropShow=this.getStr('btnCropShow',index);
      let tempPadding = this.getStr('btnPadding', index);
      let tempCropper = this.getStr('cropper', index);
      this.$refs[tempCropper][0].goAutoCrop()
      //显示保存截图按钮,隐藏截图按钮
      this[tempBtnSaveCropShow] = true;
      //this[tempBtnCropShow]=false;
      this[tempPadding] = '6px 6px';
    },
    // 保存裁剪
    saveCrop(index) {
      let tempBtnSaveCropShow = this.getStr('btnSaveCropShow', index);
      let tempCropper = this.getStr('cropper', index);
      let tempImgUrl = this.getStr('imgUrl', index);
      //隐藏保存截图按钮,显示截图按钮
      this[tempBtnSaveCropShow] = true;
      let tempPadding = this.getStr('btnPadding', index);
      // 获取截图的base64 数据getCropData
      this.$refs[tempCropper][0].getCropData(data => {
        //截取之后的base64图片
        this[tempImgUrl] = data;
        this[tempPadding] = '6px 6px';
      })
    },
    // 图片左旋转90°
    rotateLeft(index) {
      let tempCropper = this.getStr('cropper', index);
      let tempBtnSaveCropShow = this.getStr('btnSaveCropShow', index);
      /* let tempBtnCropShow=this.getStr('btnCropShow',index);*/
      let tempPadding = this.getStr('btnPadding', index);
      this.$refs[tempCropper][0].clearCrop()//清除截图
      //旋转
      this.$refs[tempCropper][0].rotateLeft();
      //显示保存截图按钮,隐藏截图按钮
      this[tempBtnSaveCropShow] = true;
      //this[tempBtnCropShow]=true;
      this[tempPadding] = '6px 6px';
    },
    // 图片右旋转90°
    rotateRight(index) {
      let tempCropper = this.getStr('cropper', index);
      let tempBtnSaveCropShow = this.getStr('btnSaveCropShow', index);
      //let tempBtnCropShow=this.getStr('btnCropShow',index);
      let tempPadding = this.getStr('btnPadding', index);
      this.$refs[tempCropper][0].clearCrop()//清除截图
      this.$refs[tempCropper][0].rotateRight();
      //显示保存截图按钮,隐藏截图按钮
      this[tempBtnSaveCropShow] = true;
      //this[tempBtnCropShow]=true;
      this[tempPadding] = '6px 6px';
    },
    //提交
    upload() {
      new Promise((resolve) => {
        this.cropThePictureLoading = Loading.service({
          lock: true,
          text: '正在处理作答图片...',
          background: 'rgba(0, 0, 0, 0.8)'
        });
        resolve(this.cropThePictureLoading)
      }).then(res => {
        let self = this;
        self.params.afterUploadFiles = [];
        if (self.uploadFiles.length > 0) {
          for (let i in self.uploadFiles) {
            // 这里就应该是在 imgUrl 拿数据
            let tempImgUrl = this.getStr('imgUrl', i);
            let img = new Image();
            img.src = this[tempImgUrl] || '';
            img.onload = () => {
              if (img.width < 500 && img.height < 500) {
                this.cropThePictureLoading.close();
                handleAlert("图片太小，会影响图片的清晰度,请重新拍照", 'warning');
              } else {
                // base64数据转成图片
                let file = self.dataURLtoFile(this[tempImgUrl] || '', i);
                //如果图片小于100kb，不压缩
                if (this[tempImgUrl].length <= 100 * 1024) {
                  self.uploadAjax(i, file);
                } else {
                  //进行图片压缩
                  self.compress(file).then((tempFile) => {
                    //压缩后进行上传
                    self.uploadAjax(i, tempFile, file);
                  });
                }
              }
            }
          }
        } else {
          res.close();
          handleAlert("请点击作答图像下的点击拍照，至少上传一张图片！", "info");
          return;
        }
      })
    },
    dataURLtoFile(dataUrl, filename) {//将base64转换为文件
      let arr = dataUrl.split(','), mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {type: mime});
    },
    saveTheOriginalImageLogic(resp, txxx, originFile) {
      let sourceFileUploadformData = new FormData();
      sourceFileUploadformData.append("OSSAccessKeyId", resp.content.aliYunOssSign.accessid)
      sourceFileUploadformData.append("policy", resp.content.aliYunOssSign.policy)
      sourceFileUploadformData.append("signature", resp.content.aliYunOssSign.signature)
      sourceFileUploadformData.append("key", resp.content.aliYunOssSign.dir + "/" + txxx.imageSourceFilesPath)
      sourceFileUploadformData.append("success_action_status", "200")
      sourceFileUploadformData.append("file", originFile)
      //上传一下原始文件，以助于排查黑图问题
      this.$ajax.post(resp.content.aliYunOssSign.host, sourceFileUploadformData).then((response) => {
      })
    },
    uploadAjax(index, file, originFile) {
      let self = this;
      this.params.afterUploadFiles[index] = file;
      if (this.params.afterUploadFiles.filter((item) => item !== null).length == this.uploadFiles.filter((item) => item !== null).length) {
        this.cropThePictureLoading.close();
        let data = {
          slid: this.params.slid,
          ksdm: this.params.ksdm,
          bmh: this.params.bmh,
          ksid: this.params.ksid,
          kmdm: this.params.kmdm,
          stid: this.params.stid,
          txqys: this.params.txqys,
          fileList: []
        }
        for (let i = 0; i < this.params.afterUploadFiles.length; i++) {
          //如果未上传,就跳过
          if (this.params.afterUploadFiles[i] === undefined) {
            continue;
          }
          this.params.afterUploadFiles[i].txqy = this.params.txqys[i];
          data.fileList.push(JSON.stringify({
            size: this.params.afterUploadFiles[i].size,
            type: this.params.afterUploadFiles[i].type,
            txqy: this.params.txqys[i]
          }));
        }
        data.fileList = JSON.stringify(data.fileList);
        let loading = Loading.service({
          lock: true,
          text: '正在上传作答...',
          background: 'rgba(0, 0, 0, 0.8)'
        });
        //检查是否允许提交图片
        this.$ajax.post(localStorage.getItem('ApiUrl') + '/answer/submitStatusCheck', data).then((response) => {
          let resp = response.data;
          if (resp.success) {
            if (resp.content.txfwqlx == undefined || resp.content.txfwqlx == '') {
              handleAlert("未设置图像服务器类型,请联系管理员设置", "error");
              return false
            }
            new Promise((resolve, reject) => {
              let count = 0;
              this.params.afterUploadFiles.forEach((item) => {
                if (!('txqy' in item)) {
                  reject("图像数据已经被浏览器清除，请重新扫码拍照上传")
                }
              });
              //循环将图片上传到服务器
              this.params.afterUploadFiles.forEach((item) => {
                if (resp.content.txfwqlx === "1") {
                  let formData = new FormData();
                  formData.append("ImageFile", item);
                  formData.append("fileName", item.name);
                  formData.append("ImagePath", resp.content.txMap[item.txqy].imagePath);
                  formData.append("slid", this.params.slid);
                  formData.append("ksdm", this.params.ksdm);
                  //上传至图像服务器
                  this.$ajax.post(resp.content.txMap[item.txqy].url, formData).then((uploadToManageToolResp) => {
                    if (uploadToManageToolResp.data.success) {
                      count++
                      //排除掉空的图像,全部图像上传成功后,执行修改作答方法
                      if (count === this.params.afterUploadFiles.filter((item) => item !== undefined).length) {
                        resolve(resp.content.txMap[item.txqy]);
                      }
                    } else {
                      reject(uploadToManageToolResp.data.message);
                    }
                  }).catch(() => {
                    reject("无法访问图像服务器(ManageTool),请稍后再试");
                  })
                } else if (resp.content.txfwqlx === "2") {
                  let txxx = resp.content.txMap[item.txqy];
                  let formData = new FormData();
                  formData.append("OSSAccessKeyId", resp.content.aliYunOssSign.accessid)
                  formData.append("policy", resp.content.aliYunOssSign.policy)
                  formData.append("signature", resp.content.aliYunOssSign.signature)
                  formData.append("key", resp.content.aliYunOssSign.dir + "/" + txxx.imagePath)
                  formData.append("success_action_status", "200")
                  formData.append("file", item)
                  this.$ajax.post(resp.content.aliYunOssSign.host, formData).then((response) => {
                    //如果图像上传成功了,还需要验证下图像大小是否与上传前一致
                    if (response.status === 200) {
                      //发送原生ajax请求,防止被axios拦截器拦截
                      sendAjax({
                        url: self.params.txdz + self.params.slid + "/" + self.params.ksdm + "/" + self.params.kmdm + "/" + self.params.bmh + "/" + self.params.bmh + "_" + txxx.txqy + ".jpg?x-oss-process=image/info",
                        type: 'get',
                        dataType: 'json',
                        timeout: 5000,
                        contentType: "application/json",
                        success: function (res) {
                          //如果图像大小不一致,则上传失败
                          if (txxx.size != JSON.parse(res).FileSize.value) {
                            reject("上传图像异常,请切换浏览器或者设备重试")
                          } else {
                            //如果图像大小一致,则累计count
                            count++
                            //如果阿里云ossDebug功能开放，则上传一下原始文件
                            if (localStorage.getItem("ossDebugFunction")) {
                              if (localStorage.getItem("ossDebugFunction") == "true") {
                                self.saveTheOriginalImageLogic(resp, txxx, originFile);
                              }
                            }
                            //全部图像上传成功后,执行修改作答方法
                            if (count === self.params.afterUploadFiles.filter((item) => item !== undefined).length) {
                              resolve(resp.content.txMap[parseInt(item.txqy)]);
                            }
                          }
                        },
                        //异常处理
                        error: function (e) {
                          reject("上传图像异常,请切换浏览器或者设备重试");
                        }
                      })
                    } else {
                      reject("无法访问图像服务器(OSS),请稍后再试");
                    }
                  })
                } else {
                  reject("未设置图像服务器类型,请联系管理员设置");
                }
              })
            }).then(() => {
              this.$ajax.post(localStorage.getItem('ApiUrl') + '/answer/saveZgtAnswer', data).then((response) => {
                let resp = response.data;
                loading.close();
                if (resp.success) {
                  this.$router.push("/uploadSuccess");
                } else {
                  handleAlert(resp.message, 'error');
                }
              })
            }).catch((err) => {
              loading.close();
              handleAlert(err, 'error');
            })
          } else {
            loading.close();
            handleAlert(resp.message, 'error');
          }
        })
      }
    }
  }
}
</script>

<style scoped>
#cropper {
  margin: 0 auto;
}

.vue-cropper {
  width: 100%;
  height: 300px;
  margin: 0 auto;
}

.el-button + .el-button >>> span {
  margin-left: 0px;
}

.btnUploadImg >>> span {
  margin-left: 0px;
}
</style>
